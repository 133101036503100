import makeStyles from '@material-ui/core/styles/makeStyles';
import {
    PRIMARY, WHITE_IMPORTANT, GREEN, WHITE,
} from '@theme_color';
import {
    FlexColumn,
    FlexRow,
    CreatePadding,
    Centering,
} from '@theme_mixins';
import { FONT_14, FONT_BIG } from '@theme_typography';

const useStyles = makeStyles((theme) => ({
    shoppingCartContainer: {
        minHeight: '100vh',
        background: '#fff',
        margin: '0 18px 0 18px !important',
        padding: '42px 24px',
        [theme.breakpoints.down(768)]: {
            padding: '28px 8px',
            margin: '16px 0 226px 0 !important',
        },
    },
    container: {
        ...FlexColumn,
    },
    alert: {
        margin: 15,
    },
    iconClose: {
        ...FONT_BIG,
    },
    toolbar: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        width: '100%',
    },
    toolbarTop: {
        ...FlexRow,
        marginBottom: 7,
        alignItems: 'center',
        flexWrap: 'wrap',
        [theme.breakpoints.down(768)]: {
            justifyContent: 'flex-end',
        },
    },
    toolbarCounter: {
        flex: '1',
        display: 'flex',
        alignItems: 'center',
        '& *': {
            fontSize: '16px !important',
        },
        [theme.breakpoints.up(767)]: {
            marginLeft: '-10px !important',
        },
        [theme.breakpoints.down(768)]: {
            flex: '1 1 100%',
        },
    },
    toolbarButton: {
        fontSize: 11,
        width: 'auto',
        borderRadius: '8px !important',
        borderColor: '#D0D0D0',
        padding: '6px 10px !important',
        '&:hover': {
            color: '#231F20',
        },
        '& span *': {
            color: '#231F20',
        },
    },
    toolbarEdit: {
        flexGrow: '0',
        flexShrink: '0',
    },
    toolbarActionContainer: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'nowrap',
        flexGrow: '0',
        flexShrink: '0',
        padding: '12px 0',
    },
    iconDelete: {
        [theme.breakpoints.up(767)]: {
            marginRight: '6px',
        },
        fontSize: 19,
    },
    toolbarDelete: {
        fontSize: 11,
        width: 'auto',
        borderRadius: '8px !important',
        borderColor: '#D0D0D0',
        padding: '6px 10px !important',
        margin: '0 12px',
        '&:hover': {
            borderColor: '#D0D0D0',
        },
        '& span *': {
            color: '#231F20',
        },
        '&.MuiButton-root.Mui-disabled': {
            opacity: '0.5',
        },
    },
    iconWishlist: {
        [theme.breakpoints.up(767)]: {
            marginRight: '6px',
        },
        fontSize: 19,
    },
    toolbarWishlist: {
        fontSize: 11,
        width: 'auto',
        borderRadius: '8px !important',
        backgroundColor: '#231F20',
        padding: '6px 10px !important',
        '&:hover': {
            backgroundColor: '#231F20',
        },
        '& span *': {
            color: '#FFFFFF',
        },
        '&.MuiButton-root.Mui-disabled': {
            opacity: '0.5',
        },
    },
    items: {
        ...FlexColumn,
        padding: '0 12px',
    },
    item: {
        ...FlexRow,
        position: 'relative',
        borderBottom: '1px solid #DEDEDE',
        padding: '20px 0',
        alignItems: 'flex-start',
        '& .product-options': {
            fontSize: 11,
            paddingLeft: 0,
            marginTop: 10,
            marginBottom: 20,
        },
        '&:last-child': {
            borderBottom: '1px solid transparent',
        },
    },
    itemOos: {
        backgroundColor: '#F8F8F8 !important',
    },
    itemCheckbox: {
        '& *': {
            fontSize: '16px !important',
        },
        '&.Mui-disabled': {
            pointerEvents: 'auto !important',
        },
    },
    itemImgWrapper: {
        flex: 1,
        maxWidth: 200,
        position: 'relative',
        '& span': {
            position: 'absolute',
            top: 0,
            right: 0,
            zIndex: 1,
            minWidth: 20,
            minHeight: 15,
            backgroundColor: GREEN,
            color: WHITE,
            fontWeight: '700',
            fontSize: 10,
            padding: 5,
            borderRadius: 5,
            ...Centering,
            marginLeft: 'auto',
            marginRight: 5,
        },
    },
    itemImg: {
        width: '100%',
        display: 'block',
    },
    itemInfo: {
        flex: 2,
        ...CreatePadding(0, 12, 0, 12),
        position: 'relative',
        paddingBottom: 30,
        '& .alert': {
            marginTop: 10,
        },
        '& .product-options': {
            '& .option-wrapper': {
                fontSize: 12,
                '& .option-wrapper__item': {
                    marginLeft: 0,
                },
                '& .option-item': {
                    margin: 0,
                    marginLeft: 5,
                },
            },
        },
        '& .option-link-mobile': {
            paddingLeft: 5,
        },
    },
    itemName: {
        textDecoration: 'none',
        color: PRIMARY,
        marginBottom: 4,
        display: 'inline-block',
    },
    itemPrice: {
        position: 'absolute',
        bottom: 0,
        left: 12,
        fontWeight: 'bold',
    },
    itemActions: {
        position: 'absolute',
        right: '0px',
    },
    iconBtn: {
        display: 'block',
        position: 'relative',
        padding: '9px',
        color: WHITE_IMPORTANT,
        width: '30px',
        height: '30px',
        fontSize: '14px !important',
        background: '#000',
        margin: '5px 0',
        '&:hover': {
            background: '#fff',
            boxShadow: `inset 0px 0px 0px 1px ${PRIMARY}`,
            color: '#000 !important',
        },
    },
    icon: {
        fontSize: '14px !important',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    crosselTitle: {
        display: 'block',
        ...FONT_14,
    },
    mobileBottomSpace: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: 140,
        },
    },
    title: {
        margin: '0 0 16px 0 !important',
        fontSize: '24px',
    },
    noMarginNoPadding: {
        margin: '0 !important',
        padding: '0 !important',
    },
    btnContinueShopping: {
        width: '400px !important',
        maxWidth: '100% !important',
        background: '#FF6A00',
        borderRadius: '4px',
        '&:hover': {
            background: '#FF6A00',
        },
        [theme.breakpoints.down(768)]: {
            width: '100% !important',
        },
    },
    emptyText: {
        fontSize: '15px !important',
        margin: '0 0 14px 0',
        padding: 0,
    },
    centering: {
        ...Centering,
        width: '100%',
        minHeight: '320px',
        textAlign: 'center',
    },
    emptyContainer: {
        background: '#fff',
        margin: '0 18px 0 18px !important',
        ...CreatePadding(42, 24, 42, 24),
        [theme.breakpoints.down(768)]: {
            ...CreatePadding(28, 16, 28, 16),
            margin: '16px 0 0 0 !important',
        },
    },
    visibilityHidden: {
        visibility: 'hidden !important',
    },
    itemCount: {
        color: '#9B9B9B',
    },
    checkedPrimary: {
        color: '#FF6A00 !important',
    },
    vendorName: {
        lineHeight: '24px !important',
        fontSize: '14px !important',
    },
    formControlTop: {
        margin: '0 !important',
        padding: '0 !important',
        '& *': {
            fontSize: '16px',
        },
    },
    formControl: {
        margin: '0 !important',
        padding: '24px 0 8px 0 !important',
        borderBottom: '2px solid #DEDEDE !important',
        '& *': {
            fontSize: '16px',
        },
    },
    additionalDescContainer: {
        padding: '8px 0',
    },
    redAlert: {
        width: '100%',
        fontSize: 12,
        alignItems: 'center',
        padding: '4px 8px 3px 8px',
        textTransform: 'none',
        marginTop: '15px',
    },
}));

export default useStyles;
