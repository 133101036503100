/* eslint-disable import/prefer-default-export */
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import * as Schema from './schema';

const USING_INTERNAL = true;

const config = (isUsingInternal) => {
    const context = isUsingInternal ? { request: 'internal' } : {};

    return {
        notifyOnNetworkStatusChange: true,
        context,
    };
};

export const addWishlist = () => useMutation(Schema.addWishlist, {
    context: {
        request: 'internal',
    },
});

export const getCartData = (cartId) => useQuery(Schema.getCart,
    {
        variables: { cartId },
        context: {
            request: 'internal',
        },
        fetchPolicy: 'no-cache',
    });

export const getCartDataLazyItem = () => useLazyQuery(Schema.getCartItem,
    {
        context: {
            request: 'internal',
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    });

export const getTempCartDataLazyItem = () => useLazyQuery(Schema.getTempCartItem,
    {
        context: {
            request: 'internal',
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    });

export const getTempCartIdUser = () => useLazyQuery(Schema.getTempCartIdUser, {
    context: {
        request: 'internal',
    },
    skip: typeof window === 'undefined',
    fetchPolicy: 'no-cache',
});

/* eslint-disable-next-line no-shadow */
export const getCrossellCartDataLazy = (config = {}) => useLazyQuery(Schema.getCrossellCart(config),
    {
        context: {
            request: 'internal',
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        // skip: !cartId,
    });

export const getCartDataLazyPrice = () => useLazyQuery(Schema.getCart,
    {
        context: {
            request: 'internal',
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    });

export const getMiniCartData = (cartId) => useLazyQuery(Schema.getMiniCart,
    {
        variables: { cartId },
        context: {
            request: 'internal',
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    });

export const getCountCart = (cartId) => useQuery(Schema.getCountCart, {
    context: {
        request: 'internal',
    },
    variables: {
        cartId,
    },
    skip: cartId === '' || typeof cartId === 'undefined',
});

export const getCountTempCart = (cartId) => useQuery(Schema.getCountTempCart, {
    context: {
        request: 'internal',
    },
    variables: {
        cartId,
    },
    skip: cartId === '' || typeof cartId === 'undefined',
});

export const reOrder = () => useMutation(Schema.reOrder, {
    context: {
        request: 'internal',
    },
});

export const deleteCartItem = () => useMutation(Schema.deleteCartitem, {
    context: {
        request: 'internal',
    },
});

export const deleteTempCartItem = () => useMutation(Schema.deleteTempCartItem, {
    context: {
        request: 'internal',
    },
});

export const deleteTempCartItemBulk = () => useMutation(Schema.deleteTempCartItemBulk, {
    context: {
        request: 'internal',
    },
});

export const unselectItemFromTempCart = () => useMutation(Schema.unselectItemFromTempCart, {
    context: {
        request: 'internal',
    },
});

export const updateCartitem = () => useMutation(Schema.updateCartitem, {
    context: {
        request: 'internal',
    },
});

export const updateTempCartItem = () => useMutation(Schema.updateTempCartItem, {
    context: {
        request: 'internal',
    },
});

export const deleteMiniCartItem = () => useMutation(Schema.deleteMiniCartitem, {
    context: {
        request: 'internal',
    },
});

export const updateMiniCartitem = () => useMutation(Schema.updateMiniCartitem, {
    context: {
        request: 'internal',
    },
});

export const applyCouponToCart = (options = {}) => useMutation(Schema.applyCouponToCart, {
    ...options,
    ...config(USING_INTERNAL),
});

export const applyCouponToCartGuest = (options = {}) => useMutation(Schema.applyCouponToCartGuest, {
    ...options,
    ...config(USING_INTERNAL),
});

export const removeCouponFromCart = (options = {}) => useMutation(Schema.removeCouponFromCart, {
    ...options,
    ...config(USING_INTERNAL),
});

export const removeCouponFromCartGuest = (options = {}) => useMutation(Schema.removeCouponFromCartGuest, {
    ...options,
    ...config(USING_INTERNAL),
});

export const addSimpleProductsToCart = () => useMutation(Schema.addSimpleProductsToCart, {
    context: {
        request: 'internal',
    },
});

export const clearMagentoCart = () => useMutation(Schema.clearMagentoCart, {
    context: {
        request: 'internal',
    },
});
